import React from 'react';
import Separator from '../../components/separator';
import IntroductionSection from '../../components/introduction-section';
import LanguagesSection from '../../components/languages-section';
import ListingSection from '../../components/listing-section';
import BlogSection from '../../components/blog-section';



const Home = () => {

    const ExperienceSectionArray = [
        {
            position: "Co-Director",
            company: {
                img_src: "/cybnode_1.png",
                name: "Cybnode Ltd",
                url: "https://cybnode.com/"
            },
            location: "London, United Kingdom",
            years: "09/2021 - Present",
            summary: "Cybnode Ltd is a software and web development startup specializing in cybersecurity, spun out of Coventry University. By bridging academia and industry, we deliver secure, scalable digital solutions tailored for SMEs. Interested in working together? Contact us at info@cybnode.com.",
        },
        {
            position: "Web Developer",
            company: {
                img_src: "/FCW.png",
                name: "First Class Watches Ltd",
                url: "https://www.firstclasswatches.co.uk/"
            },
            location: "Kenilworth, United Kingdom",
            years: "06/2023 - Present",
            summary: "Developed and maintained the company's e-commerce website, improving user experience and increasing sales. Implemented new features, such as a custom watch wedding ring builder. Worked with the marketing team to create webpages. <br/><br/><b>Technologies</b><br>- JavaScript (Native / React / NextJS), PHP (Native / Laravel), Git/GitHub, HTML / CSS, MySQL, Google (Analytics / Ads), SEMrush, Adobe (Photoshop / Illustrator).",
        },
    ];

    const EducationSectionArray = [
        /*{
            position: "Doctor of Philosophy (PhD + MRes) in Sensor Technologies",
            company: {
                img_src: "/images.png",
                name: "University of Cambridge",
                url: "https://www.cam.ac.uk/"
            },
            location: "Cambridge, United Kingdom",
            years: "09/2025 - Present",
            summary: "Not Awarded (Part-time). <br/><br/><b>Relevant modules:</b> Network and Web Security, Advanced Computer Security, Infrastructure and Transport Security, Electronic and Natural Sensors, Security in Context. <br/><br/><b>Awards</b><br>- Engineering and Physical Sciences Research Council (EPSRC) Studentship recipient."
        },*/
        {
            position: "Master of Science (MSc) in Security and Resilience",
            company: {
                img_src: "/imperial.png",
                name: "Imperial College London",
                url: "https://www.imperial.ac.uk/"
            },
            location: "London, United Kingdom",
            years: "09/2023 - Present",
            summary: "In Progress (Part-time). <br/><br/><b>Relevant modules:</b> Network and Web Security, Advanced Computer Security, Infrastructure and Transport Security, Electronic and Natural Sensors, Security in Context. <br/><br/><b>Awards</b><br>- Institute for Security Science and Technology (ISST) Scholarship recipient."
        },
        {
            position: "Bachelor of Science (BSc) in Cybersecurity",
            company: {
                img_src: "/coventry.png",
                name: "Coventry University",
                url: "https://www.coventry.ac.uk/"
            },
            location: "Coventry, United Kingdom",
            years: "09/2020 - 06/2023",
            summary: "First Class Honors (83%, equivalent to 4.0 GPA). <br/><br/><b>Relevant modules:</b> Computer Systems and Networks, Web Security, Practical Cryptography, Practical Pen-Testing, Programming & Algorithms, Applied Forensics, Security Audit, Reverse Engineering, Security Management. <br/><br/><b>Awards</b><br>- Course Tutor's Prize for Best Overall BSc Project recipient<br/>- Undergraduate Student of the Year Nominee"
        },
    ];

    const LanguagesSectionArray = [
        {
            name: "English",
            level: "Native / Bilingual"
        },
        {
            name: "Hungarian",
            level: "Native /  Bilingual"
        },
        {
            name: "German",
            level: "Elementary"
        },
    ];


    return (
        <div className="Home">

            <IntroductionSection />

            <Separator title="Experience" anchor="experience" />

            <ListingSection infoArray={ExperienceSectionArray} />

            <Separator title="Education" anchor="education" />

            <ListingSection infoArray={EducationSectionArray} />

            <Separator title="Languages" anchor="languages" />

            <LanguagesSection languagesArray={LanguagesSectionArray} />

            <Separator title="Blog" anchor="blog" />

            <BlogSection />

        </div>
    );
}

export default Home;